import React, { useContext, useMemo } from "react";
import _ from "lodash";
import {
  ACCOUNT_CATEGORY,
  DEFAULT_MONTHLY_RENT,
  DEFAULT_SIGNUP_BUYING_GOAL,
  scenarioTypes,
} from "../../../commons/enum";
import { SIGN_UP_PROCESS_STEP } from "../../../commons/signup.constants";
import CustomButton from "../../../components/custom-button";
import DataLoadingContext from "../../../context/data-loading-context";
import SignupContext from "../../../context/sign-up-context";
import { useLinkedBankAccountData } from "../../../hooks/account.hooks";
import { usePropertyList } from "../../../hooks/property.hooks";
import AddPropertyComponent from "./add-property-component";
import ExistedProperty from "./existed-property";
import { useScenarioList } from "../../../hooks/scenario.hooks";

const isHomeLoanLinkedProperty = (propertyList, homeloanId) => {
  const filteredProperty = propertyList.filter(
    (property) => property.accountIds.indexOf(homeloanId) >= 0
  );
  return filteredProperty;
};

const SignupConnectProperty = () => {
  const { setSignupProcess } = useContext(SignupContext);
  const {
    data: propertyList = [],
    isLoading: isLoadingPropertyList,
  } = usePropertyList();
  const { setAppState } = useContext(DataLoadingContext);

  const { params } = useContext(SignupContext);
  const { firstName = "" } = params;
  const linkedBankAccountData = useLinkedBankAccountData();
  const { data: scenarioList = [] } = useScenarioList();

  const linkedHomeLoan = useMemo(() => {
    return [...(linkedBankAccountData?.[ACCOUNT_CATEGORY.LOAN] || [])];
  }, [linkedBankAccountData]);

  const propertyLinkedHomeLoan = useMemo(() => {
    return (
      propertyList.filter((property) => {
        return !!property?.accountIds;
      }) || []
    );
  }, [propertyList]);

  const homeLoanNotLinkedProperty = useMemo(() => {
    const notLinkList = linkedHomeLoan.filter((homeloan) => {
      return propertyLinkedHomeLoan.length
        ? !propertyLinkedHomeLoan.some((property) => {
            return property.accountIds.includes(homeloan.id);
          })
        : true;
    });
    return _.uniqBy(notLinkList, "id");
  }, [linkedHomeLoan, propertyLinkedHomeLoan]);

  const handleSetBuyingGoal = () => {
    setSignupProcess(SIGN_UP_PROCESS_STEP.SET_BUYING_GOAL);
  };

  const handleNextProcess = () => {
    if (scenarioList.length <= 0) {
      if (propertyLinkedHomeLoan.length > 0) {
        const linkedHomeloan = linkedHomeLoan.find(
          (homeloan) =>
            propertyLinkedHomeLoan[0].accountIds.indexOf(homeloan.id) >= 0
        );
        setAppState({
          pendingToCreateScenarios: [
            {
              scenarioType: scenarioTypes.REFINANCE_HOME_LOAN,
              buyingGoal: propertyLinkedHomeLoan[0].estimate,
              homeloan: Math.round(linkedHomeloan?.balance),
              firstName,
            },
            {
              scenarioType: scenarioTypes.BUY_AN_INVESTMENT,
              buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
              monthlyRent: DEFAULT_MONTHLY_RENT,
              firstName,
            },
          ],
        });
      } else {
        setAppState({
          pendingToCreateScenarios: [
            {
              buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
              firstName,
            },
            {
              scenarioType: scenarioTypes.BUY_AN_INVESTMENT,
              buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
              monthlyRent: DEFAULT_MONTHLY_RENT,
              firstName,
            },
          ],
        });
      }
    }
    setSignupProcess(SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleNextProcess();
    }
  };

  const AddProperty = ({ title = "Type in Address" }) => {
    return (
      <>
        <div className="signup-context-title">{title}</div>
        <div className="signup-property-container">
          {propertyList.map((property) => {
            return (
              <ExistedProperty key={property.propertyId} property={property} />
            );
          })}
          <AddPropertyComponent propertyList={propertyList} />
        </div>
      </>
    );
  };

  const NextButton = () => {
    return (
      <>
        <div className="signup-property-spacing" />
        <div
          className="btn custom-button-fill"
          onClick={handleNextProcess}
          role="button"
          tabIndex="0"
          onKeyPress={handleKeyDown}
        >
          Next
        </div>
      </>
    );
  };
  const renderHomeLoanLinkedProperty = () => {
    return propertyLinkedHomeLoan?.map((property) => {
      const { propertyId = "", accountIds } = property;
      return (
        <div key={propertyId} className="signup-link-property__item--container">
          {linkedHomeLoan
            .filter((homeloan) => accountIds.indexOf(homeloan.id) >= 0)
            .map((item) => {
              const {
                class: { product = "" } = {},
                institutionLogo = "",
                id,
              } = item;
              return (
                <div key={id} className="signup-link-property__item--title">
                  <div className="la-logo">
                    <img src={institutionLogo} alt={product} />
                  </div>
                  <div className="font-weight-semibold text-left">
                    {product}
                  </div>
                </div>
              );
            })}

          <div className="font-weight-semibold text-left">Type in Address</div>

          <ExistedProperty property={property} />
        </div>
      );
    });
  };

  const renderHomeLoanNotLinkedProperty = () => {
    return homeLoanNotLinkedProperty.map((item) => {
      const { class: { product = "" } = {}, institutionLogo = "", id } = item;
      return (
        <div
          key={`${product}`}
          className="signup-link-property__item--container"
        >
          <div className="signup-link-property__item--title">
            <div className="la-logo">
              <img src={institutionLogo} alt={product} />
            </div>
            <div className="font-weight-semibold text-left">{product}</div>
          </div>
          <div className="font-weight-semibold text-left">Type in Address</div>
          {isHomeLoanLinkedProperty(propertyList, id).map((property) => {
            return (
              property?.propertyId && (
                <AddPropertyComponent accountId={id} property={property} />
              )
            );
          })}
          {!isHomeLoanLinkedProperty(propertyList, id).length && (
            <AddPropertyComponent accountId={id} propertyList={propertyList} />
          )}
        </div>
      );
    });
  };

  const LinkingBankToProperty = () => {
    return (
      <>
        {!isLoadingPropertyList &&
          (propertyLinkedHomeLoan.length > 0 || propertyList.length === 0) && (
            <>
              <div className="signup-context-title">
                Link property address to loan
              </div>
              <div className="signup-link-property__container">
                {renderHomeLoanLinkedProperty()}
                {renderHomeLoanNotLinkedProperty()}
              </div>

              <div className="signup-property-spacing" />
            </>
          )}
        {propertyLinkedHomeLoan.length === 0 && (
          <AddProperty title="Add another property" />
        )}
        {propertyList.length > 0 && <NextButton />}
      </>
    );
  };

  const SignupAddProperty = () => {
    return (
      <>
        <AddProperty />

        {!isLoadingPropertyList && propertyList.length === 0 ? (
          <>
            <div className="signup-property-spacing" />
            <div className="signup-context-title">
              Don&apos;t have any properties?
            </div>
            <CustomButton
              label="Set Your Buying Goal"
              onClick={handleSetBuyingGoal}
            />
          </>
        ) : (
          <NextButton />
        )}
      </>
    );
  };

  return (
    <div className="body-context-container add-property-container align-self-center">
      {linkedHomeLoan?.length > 0 ? (
        <LinkingBankToProperty />
      ) : (
        <SignupAddProperty />
      )}
    </div>
  );
};

SignupConnectProperty.propTypes = {};

export default SignupConnectProperty;
